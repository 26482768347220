.unit-list {
  padding: .5em .5em 0 .5em;

  .unit-row {
  }

  .facilities-row {
    &.sticky {
      position: sticky;
      top: 0px;
      z-index: 999;
      animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
  }

  .facility-name {
    position: sticky;
    top: 75px;
    line-height: 19px;
    padding-top: 5px;
    padding-left: 3px;
    font-weight: bold;
    font-size: 1.1em;
    cursor: pointer;
    justify-content: space-between;
    background-color: #eee;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 2px;

    label {
      margin-left: 25px;
    }

    small {
      font-size: 0.8em;
      font-weight: normal;
      margin-left: 9px;
    }

    .title-caret {
      position: relative;
      top: 6px;
      transition: transform 0.2s ease;
    }

    &.collapse {
      display: block;
      opacity: 0.8;

      .title-caret {
        transform: rotate(-90deg); // SPIN ANIMATION
        transform-origin: 0.5em 0.25em
      }
    }

    &.expanded {
      display: block;
      opacity: 1;

      .title-caret {
        transform: rotate(0deg); // SPIN ANIMATION
        transform-origin: 0.5em 0.25em
      }
    }
  }


  .title-caret:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-top: 8px solid #000000;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }

  .title-caret:after {
    content: '';
    position: absolute;
    left: 8px;
    top: 0;
    border-top: 0px solid #fff;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
  }
}
