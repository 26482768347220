.list {
  /*
  padding: 0 0 0 0.5em;
  */
}

.progress {
  height: 4px;
  padding-left: 5px;
}

none-list {
  margin-top: 0.5em;
}

none-item {
  padding: 0.5em;
}

.kpi-view-container {
  margin-left: 8px !important;

  .progress {
    height: 4px;
    margin-left: 5px;
  }

  .none-list {
    margin-top: 5px;
    margin-left: 0px !important;
    margin-right: 8px !important;
  }

  .none-item {
    padding: 8px;
  }

  .section {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    background-color: white;
    box-sizing: border-box;
    border-radius: 2px;
  }

  .filter-section {
    margin-top: 2px;
    margin-left: 0;
  }
}

.fixed-view {
  .header-section {
    margin-bottom: 2px;
    margin-left: 8px !important;
  }
}

.compliance-view-filter-container {

  .compliance-section {
    // overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .compliance-section-list {
      will-change: unset !important; // transform
    }
  }

  .progress {
    height: 4px;
  }

  .none-list {
    margin-top: 5px;
    margin-left: 1px;
    margin-right: 1px;
  }

  .none-item {
    padding: 8px;
  }
}

.compliance-view-container {
  height: 100%;

  .compliance-section {
    // overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .compliance-section-list {
      will-change: unset !important; // transform
    }
  }

  .progress {
    height: 4px;
  }

  .none-list {
    margin-top: 5px;
    margin-left: 1px;
    margin-right: 1px;
  }

  .none-item {
    padding: 8px;
  }
}

.patient-plan {
  height: 100%;
  width: 100%;

  .plan-no-workspace {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      color: grey;
      cursor: pointer;
    }
  }

  .detail-tabs {

    .tab-container {
      height: calc(100% - 50px);

      > div {
        height: 100%;

        .react-swipeable-view-container {
          height: 100%;

          > div {
            overflow-y: auto;
          }
        }
      }
    }
  }
}

.plan-is-loading {
  position: absolute;
  top: 0;
  z-index: 1220;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}
