.MilestoneActionsHeader {

  .titleWrapper {
    margin-top: .5em;
    display: flex;
    align-items: center;
  }

  .title {
    padding-left: .5em;
  }

  .applyPlanIcon {
    padding: 8px;
    width: 40px;
    height: 40px;
  }

  .planNames {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;

    .pillWrapper {
      padding: 0px 4px;
    }

    .deletedPlan {
      text-decoration: line-through;
    }

    .filterChip {
      padding-left: 5px;
    }
  }
}
