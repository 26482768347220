.CommMsgStatusIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  margin-right: 4px;

  .CommMsgStatusIcon {
    height: 24px;
    width: 24px;
    margin-right: 2px;
  }

  .CommMsgStatusNeedsReassign {
    height: 30px;
    width: 30px;
  }
}
