.appliedFilters {
  flex: auto;
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  align-items: center;

  .pillWrapper {
    padding: 0px 4px;
  }

  .filter-chip {
    padding-left: 5px;
  }
}
