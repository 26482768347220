.expandable-list {
  &.list {
    padding: 0.1em 0.5em 0.1em 0.5em !important;
  }

  .title {
    top: -6px;
    position: relative;
    padding-left: 0.5em;
  }

  .show-all {
    top: 3px;
    position: relative;
  }

  .text-field {
    padding-left: 1.7em !important;
    padding-right: 1.7em !important;
  }

  .item {
    padding: 0.2em 0.5em 0.2em 0.5em;
    margin: -1px;
    border: 1px solid #d3d3d3;
  }

  .expanded {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
}

.barrier-section {
  .head-line-text {
    padding: 10px;
  }

  .head-line {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .detail {
      flex: auto;
      display: flex;
      align-items: center;
    }
  }

  .escalation-add-new-barrier {
    margin: 5px 18px;
  }

  .escalation-head-line {
    padding-left: 20px;
  }

  .barrier-icon {
    fill: #ef5350;
    margin-left: 6px;
  }

  .escalation-barrier-wrapper {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
  }

  .escalation-barrier-card {
    background-color: #F7F7F7;
  }
}

.barrier-note {
  display: flex;

  .text-block {
    flex: auto;
  }

  .meta-block {
    position: relative;
    float: right;
    top: 10px;
    padding-left: 0.5em;
    padding-right: 0.4em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
