.BarrierCommStatusIcons {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  padding-right: 20px;
  padding-bottom: 3px;
  z-index: 100;

  .CommStatus, .CommMethod {
    height: 18px;
    display: flex;
    align-items: center;
  }

  .ExtraPadding {
    padding-right: 5px;
  }

  .CommMethodIcon {
    width: 18px;
    height: 18px;
    vertical-align: top;
  }

  .EmailIcon {
    fill: #1589EE;
  }
}
