.excess-days-section {
  padding: 5px;

  .dimension {
    padding: 8px;
    margin-left: 0;
    margin-right: 0;
  }

  .title {
    margin-right: 15px;
  }

  .excess-days-chart {
    overflow-x: scroll;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .selected {
    font-size: 14px;
    font-weight: 500;
    margin-left: 15px;
  }
}

.filter-section-container {
  .section {
    padding: 3px 3px 1px 8px;
    margin-left: 0;
  }

  .filter-chip {
    margin: 5px 3px 5px 3px;
  }

  .filter-label {
    padding: 10px 4px 10px 5px;
  }

  .label-column {
    min-width: 110px;
    max-width: 110px;
  }

  .chip-column,
  .number-input-column {
    display: flex;
    flex-wrap: wrap;
  }

  .number-input-toggle {
    padding-left: 5px;
    padding-right: 16px;
    margin-top: 5px;
    white-space: nowrap;
  }

  .number-input-label {
    padding-left: 8px;
    margin-top: 8px;
    white-space: nowrap;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 4px;
  }
}

.kpi-header-section {
  padding: 10px 5px 5px 10px;

  .row1,
  .row2 {
    margin-left: 0;
    margin-right: 0t;
  }

  .facility-col {
    padding-left: 0;
    padding-top: 6px;
  }

  .facilities-col {
    padding-left: 0;
    padding-top: 3px;
    z-index: 1000;
  }

  .capped-col {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    z-index: 1001;
    padding-top: 4px;

    .capped-label {
      margin-right: 5px;
      padding-top: 2px;
    }

    .capped {
      width: calc(100% - 70px);
    }
  }

  .oe-column {
    padding-left: 0;

    .oe-box {
      display: inline-block;
      width: 50px;
      text-align: center;
      margin-right: 8px;
      padding: 5px;
      border: 1px solid lightgray;
    }
  }
}

.sort-container {
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;

  .sort-container-list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
    flex: auto;
    align-items: center;

    > span {
      padding: 3px;
      margin-right: 3px;
    }

    > div {
      margin-right: 3px !important;
    }
  }

  .sort-container-info {
    height: 100%;
    display: flex;
    flex: 0 0 250px;
    align-self: flex-end;
    align-items: center;

    > span {
      padding: 5px;
      margin-right: 3px;
    }
  }
}
