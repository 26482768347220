.actionSectionTable {
  .tableHeadCell {
    text-align: center;
    vertical-align: middle;
    padding: 15px 20px;
  }

  .tableCell {
    vertical-align: middle;
    padding: 5px 20px;
  }


  .dueDateInPatientColumnHeader {
    @extend .tableHeadCell;
    width: 100px;
    min-width: 100px;
    text-align: right;
  }

  .dueDateObservationColumnHeader {
    @extend .tableHeadCell;
    width: 135px;
    min-width: 135px;
    text-align: right;
  }

  .dueDateColumnCell {
    @extend .tableCell;
    text-align: right;
  }

  .overdue {
    color: #e57373;
  }

  .dueDateColumnContent {
    height: 100%;
    vertical-align: middle;
  }


  .trackColumnHeader {
    @extend .tableHeadCell;
    width: 50px;
    min-width: 50px;
  }

  .trackColumnCell {
    @extend .tableCell;
    text-align: center;
  }

  .trackColumnCell img {
    width: 30px;
    height: 30px;
  }

  .planColorDotHeader {
    width: 30px;
    min-width: 30px;
  }


  .todoColumnHeader {
    @extend .tableHeadCell;
    text-align: left;
    width: 100%;
  }

  .todoColumnCell {
    @extend .tableCell;
    height: 100%;
    word-break: break-word;
  }

  .todoTextWrapper {
    display: flex;
  }

  .todoEditableText {
    width: calc(100% - 40px);
  }

  .strikethroughText {
    text-decoration: line-through;
  }


  .statusColumnHeader {
    @extend .tableHeadCell;
    width: 110px;
    min-width: 110px;
  }

  .statusColumnCell {
    @extend .tableCell;
    text-align: center;
  }

  .statusColumnButtonWrapper {
    min-width: 130px;
  }

  .statusIconButton {
    width: 30px;
    height: 30px;
    padding: 0px;
    margin: 0px 7px;
  }

  .todoInformationRow {
    text-align: right;
    margin: 5px 10px 15px 10px;

    .updatedInfo {
      margin-right: 10px;
    }
  }

  .rcorner {
    border-radius: 50%;
    height: 1.65em;
    width: 1.75em;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: white;
    font-family: sans-sarif, arial;
    font-weight: bold;
    font-size: 1.5em;
  }

  .task {
    @extend .rcorner;
    background-color: rgb(232, 222, 28)
  }

  .progression {
    @extend .rcorner;
    background-color: rgb(58, 195, 64)
  }

  .discharge {
    @extend .rcorner;
    background-color: rgb(59, 188, 195)
  }

  .iconSection {
    display: flex;
    justify-content: flex-end;
  }
}

.noTodoFoundRow {
  vertical-align: middle;
  text-align: center;
  height: 40px;
  line-height: 40px;
}

.planColorDot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}
