.BarrierRecipient {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 75%;
  margin-top: 10px;
  margin-bottom: 10px;

  .SendToIconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
    padding-top: 8px;

    .SendToIcon {
      width: 22px;
      height: 22px;
      background-image: url('../../../image/img/send-to.svg');
      background-repeat: no-repeat;
    }
  }
}
