.SendBarrierInfoDialog {
  color: #4A4A4A;

  .Contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .CloseIcon {
    position: absolute;
    top: 0;
    right: 0;
  }
}
