.click-text {
  cursor: pointer;
  color: #337ab7;
  &:hover {
    text-decoration: underline;
  }
}

.ellipsis-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
