.collapsed-barrier-container {
  line-height: 40px;
  border-bottom: 1px solid #d3d3d3;
  margin: 0 !important;

  .barrier-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .icons {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 !important;
    /*top: 4px;*/
  }

  .note-icon {
    fill: #fdd835;
  }

  .barrier-icon {
    margin-left: 13px;
    position: relative;
  }

  .owner-role {
    height: 40px;
  }

  .owner-name {
    white-space: nowrap;
    overflow: hidden;
  }
}

.barrier-icon {
  fill: #ef5350;
}

.plan-header {
  .row1 {
    line-height: 30px;
  }
  .row1col2col1 {
    height: 48px;
    margin-left: -30px;
  }
  .row1col2col2 {
    height: 48px;
  }
  .row1col3 {
    top: -11px;
  }
  .row2 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .row2col2 {
    padding: 0 !important;
  }

  .row3col2 {
    padding: 0 !important;
  }
  .row3 {
    line-height: 25px;
  }

  .col4 {
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    padding-top: 5px;
  }

  .text {
    text-overflow: ellipsis !important;
    white-space: nowrap;
    overflow: hidden;
  }
  .noteIcon {
    fill: #fdd835;
    vertical-align: -4px;
  }

  .barrierIcon {
    margin-left: 2px;
    margin-right: 2px;
    fill: #bdbdbd;
    vertical-align: -4px;
  }
  .actionIcon {
    margin-left: 2px;
    margin-right: 2px;
    fill: #1e88e5;
    vertical-align: -4px;
  }

  .overtime {
    color: #e57373;
  }

  .milestoneSummaryIcon {
    display: inline-block;
  }

  .clickText {
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    color: #337ab7;
    &:hover {
      text-decoration: underline;
    }
  }

  .OE {
    padding-left: 4px;
    padding-right: 4px;
    margin-left: 10px;
    display: inline-block;
    width: 80px;
    text-align: center;
  }

  .unknownOE {
    background-color: #eeeeee;
  }

  .onTrackOE {
    background-color: #a5d6a7;
  }

  .cautiousOE {
    background-color: #fff59d;
  }

  .dangerOE {
    background-color: #ef9a9a;
  }

  .overtime {
    color: #e57373;
  }

  .pastDischarge {
    color: #ef5350;
  }

  .pastTransfer {
    color: #ef5350;
  }

  .milestoneSummaryIcon {
    display: inline-block;
  }

  .barrierIcon {
    margin-left: 2px;
    margin-right: 2px;
    fill: #ef5350;
    vertical-align: -4px;
  }
}

.plan-list-filter {
  margin-left: 3px;
  padding-bottom: 2px;
  background: white;
  border-left: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;

  .filter-separator {
    margin: 5px 8px 3px 8px;
    border-left: 1px solid darkgray;
    border-right: 1px solid darkgray;
    height: 32px;
  }

  .filter-label {
    width: 110px;
  }

  .filter-section {
    display: flex;
    padding-left: 10px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    > div {
      margin: 2px !important;
    }
  }
}

.priority-section {
  .header {
    display: flex;
    justify-content: space-between;
  }

  .personnel-info {
    position: relative;
    top: 10px;
  }

  .label {
    font-family: 'sans-serif';
    font-weight: 600;
    color: #616161;
  }

  .name {
    font-family: 'sans-serif';
    font-weight: 500;
    color: #9e9e9e;
  }
}

ul{
  padding:0px!important
}

.iconButtonStyle {
  border: 10px!important;
  box-sizing: border-box!important;
  display: inline-block!important;
  font-family: Roboto, sans-serif!important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)!important;
  cursor: pointer!important;
  text-decoration: none!important;
  margin: 0px 0px 0px 5px!important;
  padding: 0px!important;
  outline: none!important;
  font-size: 0px!important;
  font-weight: inherit!important;
  position: relative!important;
  overflow: visible!important;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms!important;
  width: 16px!important;
  height: 16px!important;
  border-radius: 3px!important;
  top: 3px!important;
  background-color: rgb(238, 238, 238)!important;
  svg{
    display: inline-block!important;
    color: rgb(189, 189, 189)!important;
    fill: currentcolor!important;
    height: 16px!important;
    width: 16px!important;
    user-select: none!important;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms!important;
  }
}
.reactDatepicker {
  float: left;
}
.clockIcon {
  float: left;
  margin-right: 3px;
}
.clockIconSmall {
  float: left;
  height: 20px;
}
.calendarSelection {
  color: #337ab7;
  cursor: pointer;
}
.calendarSelectionPast {
  color: #e53935;
  cursor: pointer;
}

.popover-content{
  text-align: center;
  button {
    margin: 5px;
  }
}
.popover {
  max-width: 700px;
  width: auto;
}
.iconSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.iconWrapper {
  display: flex;
  align-items: center;
  margin-right: 2px;
  margin-left: 2px;
}
.barrierMessageStatusIcon {
  width: 24px;
  height: 24px;
  margin-right: 2px;
}
.escalationIcon {
  margin-left: 2px;
  margin-right: -2px;
}

.react-datepicker {
  font-size: 15px;
  width: max-content;
  height: 315px;
}

.react-datepicker__time-container {
  border-left: none;
}

.react-datepicker__current-month {
  font-size: 16px;
}
.react-datepicker-time__header {
  font-size: 16px;
  padding: 11px 10px;
}
.react-datepicker__header {
  height: 70px;
}

.react-datepicker__time-list {
  overflow-x: hidden;
}

.react-datepicker__time-list-item {
  margin-top: 8px;
  margin-right: 2px;
  padding: 2px 10px !important;
}

.react-datepicker__navigation {
  top: 14px;
}

.react-datepicker__day-name, .react-datepicker__day {
  margin: 7px;
}
