.jvionIcon-H {
  background-color: #f33a30;
}

.jvionIcon-M {
  background-color: #CB7F2A;
}

.jvionIcon-L {
  background-color: #1e88e5;
}

.jvionIcon {
  fill: #ffffff;
  margin-left: 2px;
  margin-right: 2px;
  vertical-align: -4px;
}

.jvion-scroll-under {
  overflow-Y: hidden;
  overflow-X: hidden;
}

.jvion-summary {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  .jvion-info {
    position: absolute;
    right: 0;
    background-color: white;
    width: 0;
    height: 165px;
    transition: width 0.5s;
    overflow-y: auto;
    overflow-x: hidden;
    > div {
      padding: 10px;
      width: 670px;
    }

  }



  .expanded {
    border-top: 1px solid #b2ebf2;
    border-bottom: 1px solid #b2ebf2;
    border-left: 1px solid #b2ebf2;
    border-right: 1px solid #b2ebf2;
    width: 670px;
    transition: width 0.5s;
  }

  .jvion-tab {
    background-color: #4dd0e1;
    cursor: pointer;
    z-index: 1;
    border: 1px solid white;
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0.7;
  }

  .selected-tab {
    background-color: #00bcd4;
    opacity: 1;
  }
}

.vector-section {
  height: 100%;

  .jvlabel {
    font-weight: 500;
  }

  .header1 {
    padding: 10px 15px 10px 15px;
    display: flex;
    justify-content: space-between;
  }
  .header2{
    padding: 10px 0px 10px 0px;
  }
}

.vector {
  padding: 5px;

  .jvlabel {
    font-weight: 500;
  }

  .condition {
    padding-bottom: 5px;
  }

  .risk-factor {
    padding-left: 0.5em;
    padding-bottom: 5px;
  }

  .risk-factor-desc {
    display: flex;
    flex-wrap: wrap;
    padding-left: 12px;
    padding-right: 12px;
  }

  .recommendation {
    padding-left: 0.5em;
  }

  .high-risk {
    color: #f33a30;
  }

  .mid-risk {
    color: #CB7F2A;
  }

  .low-risk {
    color: #1e88e5;
  }
}

.historical-vector {
  padding: 10px;
  margin-bottom: 2px;

  .label {
    color: black;
    font-weight: 500;
  }

  .label-smaller {
    padding-top: 5px;
    padding-left: 5px;
    font-size: smaller;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }
}
