.BarrierCommHistorySection {
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .HistoryHeader {
    display: flex;
    margin: 10px 0;
    padding-bottom: 10px;
    border-bottom: #b5b5b5 1px solid;
  }

  .HistoryRow {
    display: flex;
    align-items: center;
    margin: 5px 0;

    .CommMethodAndTime {
      display: flex;
      align-items: center;
    }

    .NameColumn {
      padding-left: 10px;
    }

    .CommMethodIcon {
      display: flex;
      margin-right: 10px;
    }

    .HistoryText {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > div {
      width: 33%;
    }
  }

  .Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
  }

  .NoHistory {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
