.compliance-item {
  /* top | right | bottom | left */
  padding: 5px 12px 5px 12px;
  margin: 5px 9px 5px 9px !important;

  .line {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .primary-patient-info-line {
    overflow: hidden;
    white-space: nowrap;
    cursor: default;
  }

  .decision-time-not-set {
    color: #e57373;
  }

  .overtime {
    color: #e57373;
  }

  .clickable {
    cursor: pointer;
  }

  .patient-info {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .patient-problem {
    padding-left: 0 !important;
    padding-right: 0 !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .compliance-info {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .compliance {
    display: inline-block;
    border: 1px solid #bdbdbd;
    padding: 1px 5px 1px 5px;
    border-radius: 3px;
    margin-right: 5px;
    cursor: default;
  }

  .compliance-issue {
    cursor: pointer;
    background: #ef9a9a;
  }

  no-plan-discharge {
    cursor: pointer;
  }

  .plan-discharge-in-past {
    color: #e53935;
  }

  .pastTransfer {
    color: #ef5350;
  }
}

.compliance-view-container {
  .compliance-table {
    margin: 50px 0.5em 0.5em 0.5em;
    max-height: 250px;
  }

  .filter-container {
    position: fixed;
    z-index: 1;
    top: 0;
    width: calc(100% - 50px);
    margin-top: 50px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    background: white;
    border-left: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;

    .filter-section {
      overflow: hidden;
      padding: 3px 3px 1px 4px;
    }

    .filter-list {
      display: flex;
      flex-wrap: wrap;
      padding-left: 4px;
      padding-top: 2px;
    }

    .filter-item {
      margin: 0.3em 0.2em 0.2em 0.2em;
    }

    .chip-column {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .cell {
    border: 1px solid lightgrey;
    text-align: center !important;
    height: 10px !important;
    padding: 10px 5px 10px 5px !important;
    cursor: pointer;
  }

  .cell-nopadding {
    border: 1px solid lightgrey;
    text-align: center !important;
    height: 10px !important;
    cursor: pointer;
    padding: 0px !important;
  }

  .area-cell {
    cursor: default;
  }

  .icon {
    color: red !important;
    position: relative;
    padding: 0px;
    margin: 0px;
    width: 16px;
    height: 16px;
  }

  .ascending {
    top: 2px;
  }

  .descending {
    top: 3px;
  }

  .selected {
    background: #ef9a9a !important;
  }

  .default-adjustment {
    width: calc(100% - 16px) !important;
  }

  .windows-adjustment {
    width: calc(100% - 17px) !important;
  }

  .mac-safari-adjustment {
    width: calc(100% - 15px) !important;
  }

  .mac-chrome-adjustment {
    width: calc(100% - 19px) !important;
  }
}

.compliance-view-filter-container {
  .compliance-table {
    margin: 30px 0.5em 0.5em 0.5em;
    max-height: 250px;
  }

  .filter-container {
    position: fixed;
    z-index: 1;
    top: 0;
    width: calc(100% - 50px);
    margin-top: 50px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    background: white;
    border-left: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;

    .filter-section {
      overflow: hidden;
      padding: 3px 3px 1px 4px;
    }

    .filter-list {
      display: flex;
      flex-wrap: wrap;
      padding-left: 4px;
      padding-top: 2px;
    }

    .filter-item {
      margin: 0.3em 0.2em 0.2em 0.2em;
    }

    .chip-column {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .cell {
    border: 1px solid lightgrey;
    text-align: center !important;
    height: 10px !important;
    padding: 10px 5px 10px 5px !important;
    cursor: pointer;
  }

  .cell-nopadding {
    border: 1px solid lightgrey;
    text-align: center !important;
    height: 10px !important;
    cursor: pointer;
    padding: 0px !important;
  }

  .area-cell {
    cursor: default;
  }

  .icon {
    color: red !important;
    position: relative;
    padding: 0px;
    margin: 0px;
    width: 16px;
    height: 16px;
  }

  .ascending {
    top: 2px;
  }

  .descending {
    top: 3px;
  }

  .selected {
    background: #ef9a9a !important;
  }

  .default-adjustment {
    width: calc(100% - 16px) !important;
  }

  .windows-adjustment {
    width: calc(100% - 17px) !important;
  }

  .mac-safari-adjustment {
    width: calc(100% - 15px) !important;
  }

  .mac-chrome-adjustment {
    width: calc(100% - 19px) !important;
  }
}
