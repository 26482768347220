.app-container {
  width: 100%;
  height: 100%;

  .nav-container {
    width: 50px;
  }

  .app-route-container {
    position: absolute;
    top: 50px;
    left: 50px;
    width: calc(100% - 50px); // Account for the left NavBar
    height: calc(100% - 50px); // Account for the appbar
  }

  .app-bar {
    position: relative;
    padding-right: 0 !important;
  }

  .icon-button {
    margin-top: 1em;
  }

  .tools-left {
    vertical-align: 5px;
  }

  .highlighter > div {
    // box-shadow: #fff59d 0px 1px 6px, #fff59d 0px 1px 4px !important;
    // transition: box-shadow 1000ms ease-in;
    // border: 1px solid #fff59d;

    // border: 5px solid #fff59d;
    // margin: -5px;

    box-shadow: #ffeb3b 0px 1px 6px, #ffeb3b 0px 1px 4px;
  }
}

.paper {
  // padding: 0.3em;
  // margin-bottom: 0.6em;
  // float: left;
  // width: 100%;
  border-radius: 2px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
}

.scroll-indicator {
  display: flex;
  justify-content: center;

  .loading {
    display: inline-block;
    position: relative !important;
  }
}

.select {
  background-color: #00bcd4;
  color: white !important;
  font-family: Roboto, sans-serif;
  border: none;
  font-size: 14px;
  font-weight: 500;
}
