.cl-button-toolbar {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  padding-right: 1em;
  height: 54px;
}

.app-header {
  background: #ff9800;
  width: 100%;
  height: 50px;
}

.nav-container {
  width: 50px;
}

.nav-container::-webkit-scrollbar {
  display: none;
}

//Below is is used for now for the fixed view prototype
.fixed-view {
  .fixed-header {
    position: fixed;
    top: 0;

    width: calc(100% - 50px);
    margin-top: 50px; //Account for the top AppBar
    z-index: 1210;
  }

  .fixed-footer {
    position: fixed;
    bottom: 0;
    z-index: 1210;

    width: calc(100% - 50px); //Account for the left NavBar
    //margin-top: 50px;//Account for the top AppBar
  }

  .fixed-container {
    position: absolute;
    display: block;

    width: 100%;

    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .container-item {
      display: block;

      .container-item-text {
        display: block;

        height: 100px;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 15px;

        border-radius: 2px;
      }
    }
  }
}
