.milestone-summary-status-icon {
  position: relative;
  width: 24px;

  .secondary {
    position: absolute;
    top: 0;
    right: 50%;
    width: 50%;
    overflow: hidden;
  }

  .warning-zero {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: black;
  }

  svg: {
    display: block;
  }
}

error-text {
  text-align: right;
  color: #424242;
  position: relative;
  height: 10px;
  font-size: 10px;
}

at-max-characters {
  color: #f44336;
}

.milestone-action {
  padding: 0.1em 0.5em 0.1em 0.1em;
  border-bottom: 1px solid #d3d3d3;
  display: flex;
  flex-direction: column;

  .top-half {
    display: flex;
    align-items: center;
    height: 75px;
  }

  .primary-line {
    width: calc(100% - 100px);
    position: relative;
    margin-left: 5px;
  }

  .primary-line-expanded {
    width: calc(100% - 90px);
    margin-left: 5px;
  }

  .secondary-line {
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .milestone-meta {
    display: flex;
    width: 450px;
    flex-direction: column;
    align-items: flex-end;
    flex: auto;
  }
}

.milestone-section {
  .milestone {
    padding: 5px;
    position: relative;
    margin-bottom: 1px;

    .action-section {
      max-height: 250px;
      overflow-y: auto;
    }
  }

  .description {
    position: relative;
    top: -5px;
  }

  .expanded {
    margin: 5px;
  }

  .line {
    display: flex;
    justify-content: space-between;
  }
}
