.unit-row-container {
  padding: 5px 5px 5px 5px !important;
  margin-bottom: 10px;
  float: left;
  width: 100%;
}

.unit-row-fixed-col, .unit-row-calc {
  float: left;
}

.unit-row-fixed-col {
  width: 160px;
}

.unit-row-calc {
  width:calc(100% - 160px);
}

.unit-button {
  min-width: 140px;
}

.bed-button {
  min-width: 44px;
}