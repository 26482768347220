.Person {
  display: flex;
  margin-right: 10px;

  .PersonIconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;

    .RecipientIcon {
      background-image: url('../../../../image/img/person-to.svg');
      width: 18px;
      height: 18px;
      background-repeat: no-repeat;
    }

    .SenderIcon {
      background-image: url('../../../../image/img/person-from.svg');
      width: 18px;
      height: 18px;
      background-repeat: no-repeat;
    }
  }

  .NameText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}



