html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  //overflow: hidden;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

.care-advance-app {
  background: #eeeeee; //grey 200 or theme.palette.background.contentFrame
  width: 100%;
  height: 100%;

  -webkit-tap-highlight-color: rgba(0,0,0,0); //This prevents the iPad flicker when tapped

  .no-webkit-autofill input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #eeeeee inset; //grey 200
  }
}

.app-container {
  width: 100%;
  height: 100%;
}

.app-route-container {
  position: absolute;
  top: 50px;
  left: 50px;
  width: calc(100% - 50px);//Account for the left NavBar
  height: 100%;
}

.care-advance-app {
  @import 'containers/all';
  @import 'components/all';
  @import 'layout/all';
  @import 'vendors/all';
}

.patient-info-image {
  margin-right: 4px;
}

.tag-image {
  margin-right: 4px;
  vertical-align: baseline !important;
}

.alert-image {
  margin-right: 4px;
  vertical-align: baseline !important;;
}

.discharge-delay {
  margin-right: 4px;
  width: 16px;
  vertical-align: baseline !important;;
}

.is-past {
  color: red;
}

.data-table-header-row {
  margin: 0;
  border-left: 1px solid #d3d3d3;
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
}

.data-table-header-col {
  margin: 0;
  padding: 0;
  text-align: center;
  background: white;
  border-right: 1px solid #d3d3d3;
}

.data-table-data-row {
  margin: 0;
  border-left: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
}

.data-table-data-col {//1
  margin: 0;
  padding: 0;
  height: 36px;
  background: white;
  border-right: 1px solid #d3d3d3;
}


.data-table-data-text-col {//2
  text-align: center;
  vertical-align: middle;
  position: relative;
  line-height: 36px;
}

.data-table-button {//3
  width: 100% !important;
  min-width: 44px !important;

  span {
    //This is to provide max width for the button label text
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: normal !important;
  }
}
