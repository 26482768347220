.NameDisplay {
  display: flex;
  padding-left: 10px;

  .OnlySender {
    padding-left: 10px;
  }

  .Person {
    max-width: 50%;
  }

  .Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
  }
}
