.hipaaAuditReport {

  .hipaaButtonRow {
    float: right;
    padding-top: 20px;
    padding-right: 20px;
  }
}

.hipaaReportDialog {
  display: flex;
  flex-direction: column;

  .reportRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
  }

  label {
    margin-bottom: inherit;
    font-weight: inherit;
    width: 100px;
  }

  .errorSection {
    padding-top: 10px;
    color: red;
  }
}
