.escalation-container,
.plan-list-container,
.kpi-view-container {
  height: 100%;

  .plan-list {
    .plan-header {
      padding: 5px;
    }
  }

  .escalation-list,
  .plan-list,
  .workspace-section {
    height: calc(100% - 40px);
    overflow: hidden;
    // overflow-y: auto;
    // overflow-x: hidden;
    // -webkit-overflow-scrolling: touch;

    .section-row {
      margin-top: 5px;
      margin-bottom: 2px;
      margin-left: 2px;
      margin-right: 8px !important;
    }

    .plan-header {
      .row1 {
        line-height: 30px;
      }
      .row1col2col1 {
        height: 48px;
        margin-left: -30px;
      }
      .row1col2col2 {
        height: 48px;
      }
      .row1col3 {
        top: -11px;
      }
      .row2 {
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .row2col2 {
        padding: 0 !important;
      }

      .row3col2 {
        padding: 0 !important;
      }
      .row3 {
        line-height: 25px;
      }

      .col4 {
        text-align: right;
        white-space: nowrap;
        overflow: hidden;
        padding-top: 5px;
      }

      .text {
        text-overflow: ellipsis !important;
        white-space: nowrap;
        overflow: hidden;
      }
      .noteIcon {
        fill: #fdd835;
        vertical-align: -4px;
      }

      .barrierIcon {
        margin-left: 2px;
        margin-right: 2px;
        fill: #bdbdbd;
        vertical-align: -4px;
      }

      .iconSection {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 2px;
      }

      .iconWrapper {
        display: flex;
        align-items: center;
      }

      .barrierMessageStatusIcon {
        width: 24px;
        height: 24px;
      }

      .escalationIcon {
        margin-left: 2px;
        margin-right: -2px;
      }

      .actionIcon {
        margin-left: 2px;
        margin-right: 2px;
        fill: #1e88e5;
        vertical-align: -4px;
      }

      .overtime {
        color: #e57373;
      }

      .milestoneSummaryIcon {
        display: inline-block;
      }

      .click-text {
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        color: #337ab7;
        &:hover {
          text-decoration: underline;
        }
      }

      .OE {
        padding-left: 4px;
        padding-right: 4px;
        margin-left: 10px;
        display: inline-block;
        width: 80px;
        text-align: center;
      }

      .unknownOE {
        background-color: #eeeeee;
      }

      .onTrackOE {
        background-color: #a5d6a7;
      }

      .cautiousOE {
        background-color: #fff59d;
      }

      .dangerOE {
        background-color: #ef9a9a;
      }

      .overtime {
        color: #e57373;
      }

      .pastDischarge {
        color: #ef5350;
      }

      .pastTransfer {
        color: #ef5350;
      }

      .milestoneSummaryIcon {
        display: inline-block;
      }

      .barrierIcon {
        margin-left: 2;
        margin-right: 2;
        fill: #ef5350;
        vertical-align: -4px;
      }
    }
  }

  .escalation-filter {
    // margin-left: 3px;
    background: white;
    // border-left: 1px solid lightgrey;
    // border-bottom: 1px solid lightgrey;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .filter-button {
      flex: 0 0 50px; /* 0 = don't grow (shorthand for flex-grow)
                          0 = don't shrink (shorthand for flex-shrink)
                          230px = start at 230px (shorthand for flex-basis) */
      align-self: flex-end;
    }

    .drawer-button {
      flex: 1;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      flex: auto;
      padding-left: 10px;
      align-items: center;
    }

    .filter-chip {
      padding-left: 5px;
      padding-top: 5px;
    }

    .item {
      margin: 5px 3px 3px 3px;
    }

    .filter-drawer {
      margin-top: 50px;
      overflow: unset !important;

      .filter-container {
        padding-top: 5px;

        .filter-header {
          display: flex;
          padding-bottom: 5px;

          .filter-label {
            padding-left: 5px;
            padding-top: 5px;
          }
        }

        .filter-items {
          padding-left: 10px;
          padding-bottom: 10px;
        }

        .filter-items-flex {
          display: flex;
          align-items: center;
          height: 75px;

          .filter-padding-top-20 {
            padding-top: 20px;
          }
        }


      }

      .filter-items-units {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-left: 15px;
      }

      .filter-sub-container {
        padding-left: 10px;
      }

      .filter-subheader {
        font-size: 20px!important;
      }


    }
  }
}
