.addNewBarrierForm {

  input {
    font-size: initial;
  }

  p {
    //font-size: 0.75em;
    margin-top: 4px;
  }

  fieldset {
    padding: 0.4em 0.6em;
    margin: 0;
  }

  .newBarrierNote {
    input {
      font-size: 18px
    }

    .MuiInputLabel-outlined.MuiInputLabel-marginDense {
      line-height: 18px;
      font-size: 16px;

      &:before {
        content: "➕";
        margin-right: 7px;
      }
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      &:before {
        content: "";
        margin-right: 0;
      }
    }

    .textFieldCharacterCount {
      float: left;
    }
  }

  .newBarrierCategoryTemplate {
    display: block;

    .textFieldCharacterCount {
      position: absolute;
    }
  }

  // OVERWRITE Material-UI TextField
  .MuiButton-label {
    font-size: initial;
  }

  .MuiTypography-h6 {
    font-size: 1.25em;
  }

  .MuiOutlinedInput-root {
    background-color: #fff;
  }

  .MuiInputLabel-root {
    padding: 0 3px;
    background-color: #fff;
  }

  .newBarrierOptionsContainer {
    clear: both;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 4px;

    .newBarrierOptions {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 10px;
      width: 100%;
      margin-bottom: 10px;
    }

    .row {
      flex-direction: row;
      width: 100%;
      margin: auto;
    }

    .item {
      padding: 10px 30px;
    }

    .break {
      flex-basis: 100%;
      height: 0;
    }
  }

  .formActionButton {
    display: inline-block;
    min-width: 73px;
    margin-left: 20px;

    .MuiButton-label {
      color: #4dcedf;
      font-weight: bold;
    }
  }

  .MuiButton-containedPrimary {
    .MuiButton-label {
      color: #ffffff;
    }
  }

  .dialogContent {
    text-align: center;
  }
}
