.SendButtonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 10px;
  margin-bottom: 10px;

  .SendIcon {
    width: 20px;
    height: 20px;
  }
}
