.MessageTypeLabel {
  color: #FFFFFF;
  padding: 2px 10px;
  margin: 4px;
  font-size: 11px;
  font-weight: bold;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Sent {
  @extend .MessageTypeLabel;
  color: #696969;
  border: 1px solid #979797;
}

.Acknowledged {
  @extend .MessageTypeLabel;
  background-color: #9B9B9B;
  border: 1px solid #979797;
}

.Resolved {
  @extend .MessageTypeLabel;
  background-color: #417505;
}

.NewNote {
  @extend .MessageTypeLabel;
  background-color: #F5A623;
}

.NeedsReassignment {
  @extend .MessageTypeLabel;
  background-color: #e83d27;
  color: white;
}

.Reopened {
  @extend .MessageTypeLabel;
  background-color: #0095CB;
  color: white;
}

.Other {
  @extend .MessageTypeLabel;
  color: #696969;
  border: 1px solid #979797;
}
