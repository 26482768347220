.todoFilter {

  .filter-button {
    flex: 0 0 150px; /* 0 = don't grow (shorthand for flex-grow)
                          0 = don't shrink (shorthand for flex-shrink)
                          230px = start at 230px (shorthand for flex-basis) */
    align-self: flex-end;
    padding-left: 5px;
  }

  .filter-icon {
    vertical-align: middle;
    fill: rgb(0, 188, 212);
    margin-right: -10px;
  }

  .filter-drawer {
    overflow: unset !important;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin-top: 50px;
    }

    .filter-container {
      padding-top: 5px;

      .filter-header {
        display: flex;
        padding-bottom: 5px;

        .filter-label {
          padding-left: 5px;
          padding-top: 5px;
        }
      }

      .filter-items {
        padding-left: 21px;
        padding-bottom: 10px;
      }

      .filter-items-flex {
        display: flex;
        align-items: center;
        height: 75px;

        .filter-padding-top-20 {
          padding-top: 20px;
        }
      }


    }

    .filter-items-units {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-left: 15px;
    }

    .filter-sub-container {
      padding-left: 30px;
    }

    .filter-subheader {
      font-size: 20px!important;
    }


  }
}
