.BarrierHistoryRow {
  display: flex;
  flex-direction: column;
  background-color: #F5F5F5;
  margin-bottom: 8px;
  margin-top: 8px;
  padding: 10px 15px;
  font-size: 14px;

  .InfoSection {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .TimeSentSection {
      font-weight: bold;
      color: #696969;
    }

    .NameDisplaySection {
      width: 55%;
      margin-right: auto;
    }

    .MessageTypeLabelSection {
      margin-top: -12px;
      margin-right: -15px;
      background-color: #FFFFFF;
    }
  }
}
