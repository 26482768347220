.BarrierHistoryButton {
  display: flex;
  justify-content: center;
  align-items: center;

  .HistoryIcon {
    cursor: pointer;
    margin-right: 6px;
    margin-left: 6px;
    height: 30px;
    width: 30px;
  }
}
