.BarrierCommunicationHistoryDialog {
  color: #4A4A4A;

  .CloseIcon {
    position: absolute;
    top: 0;
    right: 0;
  }

  .NoHistory {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
